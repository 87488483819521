export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'globe',
		url: 'https://www.DiegoGeroni.com',
	},
	/*{
		id: 2,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/realDiegoGeroni',
	},*/
	/*{
		id: 3,
		name: 'Twitter',
		icon: 'twitter',
		url: 'https://twitter.com/realDiegoGeroni',
	},*/
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/diego-geroni-590b45106',
	},
	{
		id: 5,
		name: 'YouTube',
		icon: 'youtube',
		url: 'https://www.youtube.com/watch?v=12heTyn25vc',
	},
];
