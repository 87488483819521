// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'Dynamics 365',
		category: 'Dynamics 365',
		img: require('@/assets/images/web-project-2.jpg'),
	},
	{
		id: 2,
		title: 'Power BI',
		category: 'Power Platform',
		img: require('@/assets/images/PowerBIImg.jpg'),
	},
	{
		id: 3,
		title: 'Business consulting',
		category: 'Business Consulting',
		img: require('@/assets/images/ui-project-1.jpg'),
	},
	{
		id: 4,
		title: 'Green Earth Agro',
		category: 'Blockchain Technology',
		img: require('@/assets/images/GreenEarthAgro.jpg'),
	},
	{
		id: 5,
		title: '101 Blockchains',
		category: 'Blockchain Technology',
		img: require('@/assets/images/101blockchains.jpg'),
	},
	{
		id: 6,
		title: 'Selfbar',
		category: 'Blockchain Technology',
		img: require('@/assets/images/selfbar-icon.jpg'),
	},
	{
		id: 7,
		title: 'System Design ',
		category: 'Dynamics 365',
		img: require('@/assets/images/web-project-1.jpg'),
	},
	{
		id: 8,
		title: 'WMS Lead',
		category: 'Dynamics 365',
		img: require('@/assets/images/warehousemobileapps.jpg'),
	},

];

export default projects;
